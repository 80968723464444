
































/**
 * Description
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";

import Multiselect from "vue-multiselect";
import { Role } from "@/interface/RoleInterface";

import UserAccess from "@/model/User/UserAccess";

@Component({
  name: "UserRolesOptions",
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters(["getRoleList", "getOnlyRoleUser", "isUserProjectManager", "getUserAccess"])
  },
  methods: {
    ...mapActions(["getAllRole"])
  }
})
export default class UserRolesOptions extends Vue {
  /*---------- Vuex  ----------*/
  private getRoleList!: Array<Role>;
  private getOnlyRoleUser!: Array<Role>;
  private readonly getUserAccess!: UserAccess;
  readonly isUserProjectManager!: boolean;

  private getAllRole!: Function;

  /*---------- Props  ----------*/
  @Prop({ default: null }) currentUserRole!: Role | null;

  /*----------  Local data  ----------*/
  selected: Role | null = null;
  options = [];

  /*----------  Vue Life cycles  ----------*/
  async created() {



    await this.getAllRole();
    this.selected = this.currentUserRole;
    if (this.selected) {
      this.selected.label = this.handleUserRoleTranslation(this.selected?.name);
    }
  }

  handleUserRoleTranslation(role: string) {
    let haveTranslations = role in (this as any).$t("roles.labels");
    if (haveTranslations) return (this as any).$t(`roles.labels.${role}`);
    else return role;
  }
}
