
/**
 * New User form
 *
 *
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import ibomadeUserService from "@/services/ibomadeUserService";
import UserRolesOptions from "@/components/Users/UserRolesOptions.vue";
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { required, minLength, numeric, email, maxLength, helpers } from "vuelidate/lib/validators";
const userNameValidator = helpers.regex("userNameValidator", /^[a-z0-9-]*$/);
import ModelUser, { ModelUserRole } from "@/model/ModelUser";
import UserAccess from "@/model/User/UserAccess";
import { ActionMessage } from "@/model/Messages/statusMessage";
import { Role } from "@/types/user";

let userValidation = {
  username: {
    required,
    userNameValidator,
    minLength: minLength(1),
    maxLength: maxLength(255)
  },
  firstName: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(255)
  },
  lastName: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(255)
  },
  email: {
    required,
    email
  },
  institution: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(255)
  },
  mobile: {
    required,
    numeric
  },
  phone: {
    required,
    numeric
  },
  roles: {
    required
  }
};

@Component({
  name: "NewUserForm",
  components: {
    UserRolesOptions,
    Multiselect
  },

  computed: {
    ...mapGetters(["getUserAccess", "getOnlyRoleUser"])
  },
  methods: {
    ...mapActions(["getUserInfo", "getAllUserFromBackEnd", "getAllRole"])
  }
})
export default class NewUserForm extends Vue {
  private readonly getOnlyRoleUser!: Array<Role>;
  @Prop({ type: Boolean, default: false }) readonly isUpdate!: boolean;
  @Prop({ type: Boolean, default: false }) readonly isUpdateProfile!: boolean;
  @Prop({ type: Boolean, default: false }) readonly openTextArea!: boolean;
  @Prop({
    type: Object,
    default: function () {
      return new ModelUser();
    }
  })
  user!: ModelUser;

  // Vuex
  private getUserInfo!: Function;
  private getAllUserFromBackEnd!: Function;
  private getAllRole!: Function;
  readonly getUserAccess!: UserAccess;
  //Data
  userRole: ModelUserRole | null = null;
  updateStatus: null | ActionMessage = null;

  /*----------  check if the user can only create users  ----------*/
  async created() {
    if (this.getUserAccess.Access.EDIT.usersList.createOnlyUser) {
      this.getAllRole();
    }
  }

  //Vue-Validate
  validations() {
    if (this.isUpdateProfile) {
      return {
        user: userValidation
      };
    } else {
      let description = {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255)
      };
      return {
        user: { ...userValidation, description, enabled: {} }
      };
    }
  }

  // Computed
  get removeSpaces() {
    var regex = /^[a-z0-9-]*$/;
    return regex.test(this.user.username);
  }
  get isUserEnable() {
    return this.user.enabled === true;
  }
  get isOpenTextArea() {
    return this.openTextArea;
  }
  get checkTextAreaIsNoEmpty() {

    // Workaround - BAD SOLUTION
    if(this.user.roles[0] == null) {
      return true;
    }

    if (this.openTextArea) {
      return (
        (this.isOpenTextArea && this.user.stateChangeReason === null) ||
        (this.user.stateChangeReason && this.user.stateChangeReason.length < 5)
      );
    }

    else {
      return this.$v.$invalid;
    }
  }
  // Methods
  changeUserRole(newRole: Role) {
    this.user.roles = [newRole];

    if(newRole == null) {
      return this.$v.$invalid;
    }

  }
  checkUserRole(roles: Array<ModelUserRole>) {
    return roles.length !== 0 ? roles[0].id : "user";
  }
  validate() {
    this.updateStatus = null;
    var message = this.$t("users.newUserForm.formStatusMsg.errorUpdateList").toString();

    // Workaround - BAD SOLUTION
    if(this.user.roles[0] == null) {
      message = this.$t("users.newUserForm.formStatusMsg.errorRole").toString();
    }

    // Workaround - BAD SOLUTION
    if (!this.$v.$invalid && this.user.roles[0] !== null) {
      this.save();
      return false;
    } else {
      this.updateStatus = {
        class: "danger",
        msg: message
      };
    }
  }
  async updateUserProfile(user: ModelUser) {
    let userId = this.$keycloak.tokenParsed?.sub || "";
    await ibomadeUserService.updateUserProfile(userId, user).then((res) => {
      if (res.status === 200) {
        this.$emit("updateStatus", {
          class: "success",
          msg: this.$t("users.newUserForm.formStatusMsg.updateSuccess")
        });
        this.getAllUserFromBackEnd();
        this.goBack("userUpdateItSelf");
      } else {
        this.updateStatus = {
          class: "danger",
          msg: res
        };
        this.getUserInfo(this.$keycloak.tokenParsed?.sub);
      }
    });
  }
  async updateUser(user: ModelUser) {
    if (!this.user.roles?.[0]) {
      this.updateStatus = {
        class: "danger",
        msg: this.$t("users.newUserForm.required").toString()
      };
      return;
    }

    let res = await ibomadeUserService.updateUserInfo(user);
    if (res.status === 200) {
      this.$emit("updateStatus", {
        class: "success",
        msg: this.$t("users.newUserForm.formStatusMsg.updateSuccess")
      });
      this.getAllUserFromBackEnd();
      this.goBack("updateUser");
    } else {
      this.updateStatus = {
        class: "danger",
        msg: res
      };
    }
  }
  handleCreateNewUser(user: ModelUser) {
    if (this.getUserAccess.Access.EDIT.usersList.createOnlyUser) {
      user.roles = this.getOnlyRoleUser;
    }
    if (!user.roles) {
      this.updateStatus = {
        class: "danger",
        msg: this.$t("users.newUserForm.required").toString()
      };
      return;
    }

    user.language = user.language ? user.language.value : "de";
    ibomadeUserService.createUser(user).then((status) => {
      if (status === 201) {
        this.$emit("updateStatus", {
          class: "success",
          msg: this.$t("users.newUserForm.formStatusMsg.success")
        });
        // this.resetUserForm();
        this.getAllUserFromBackEnd();
        this.goBack("newUser");
      } else {
        this.updateStatus = {
          class: "danger",
          msg: this.$t("users.newUserForm.formStatusMsg.error").toString()
        };
      }
    });
  }

  async save() {
    this.updateStatus = null;
    if (this.isUpdate) {
      this.updateUser(this.user);
    } else if (this.isUpdateProfile) {
      this.updateUserProfile(this.user);
    } else {
      this.handleCreateNewUser(this.user);
    }
  }
  goBack(name: string) {
    this.$bvModal.hide(name);
  }
  checkCurrentUser(id: string) {
    return this.$keycloak.tokenParsed?.sub !== id;
  }
}
